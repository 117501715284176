import React, { Component } from 'react'
import Navigation from './Navigation'
import './decks.css'
import DecksList from './DecksList';
import { Redirect } from 'react-router-dom';

export default class Archive extends Component {
    render() {
        return (
            this.props.isUserLoggedIn 
            ?   <div className="main-wrapper">
                    <Navigation />
                    <div className="workspace-spacer">

                        <div className="workspace-wrapper">
                            <div className="workspace-title-wrapper"><h4>Archive</h4></div>
                            <div className="workspace-main-content">

                            <DecksList active={false} />

                            </div>
                        </div>
                    </div>
                </div>
            : <Redirect to="/" /> 
        )
    }
}
