import React, { Component } from 'react';
import UploadService from '../service/UploadService';
import AuthService from '../service/AuthService';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from './Spinner';
import './toast-styling.css';
export default class AddDeck extends Component {

    state = {
        title: '',
        author: '',
        type: '',
        size: null,
        fileLocation: null,
        thumbnail: null,
        publish: false,
        isLoading: false,
        service: new UploadService()
    }

    onFileChange = (e) => {
        console.log(e.target)
        console.log(e.target.files[0])
        if (e.target.files[0]) {
            const data = new FormData()
            data.append('deck', e.target.files[0])
            console.log(e.target.files[0])
            if (e.target.files[0].size > (50 * 1024 * 1024)) {
                toast.error(<><img src="/images/toastify-cross.svg" alt="Something went wrong" />File too large</>);
            } else {
                this.setState({
                    size: e.target.files[0].size
                })

                this.state.service.uploadDeck(data)
                    .then(res => {
                        console.log("Response from api :", res)
                        this.setState({
                            fileLocation: res.deckUrl,
                            thumbnail: res.thumbnail,
                            publish: true
                        })
                    })
                    .catch(e => console.log("Error", e))
            }
        } else {
            this.setState({
                publish: false
            })
        }

    }

    onChangeHandler = (e) => {
        const { name, value } = e.target

        this.setState({
            [name]: value
        })
    }

    onClickHandler = (e) => {
        console.log("onClickHandler triggered")
        e.preventDefault();
        console.log("onClickHandler triggered", this.state.type )
        if (this.state.size > (5 * 1024 * 1024) && this.state.type === "Free") {
            toast.error(<><img src="/images/toastify-cross.svg" alt="Something went wrong" />File exceeds 5MB</>);
        } else if (this.state.type === "Pro") {
            console.log("PRO triggered")
            const authService = new AuthService()
            authService.isAuthenticated()
                .then(res => {
                    if (res.credits === 0) {
                        toast.error(<><img src="/images/toastify-cross.svg" alt="Something went wrong" />Insufficient credits</>);
                    } else {
                        this.setState({
                            isLoading: true
                        })
                        this.state.service.publishDeck(this.state.fileLocation, this.state.thumbnail, this.state.title, this.state.author, this.state.type)
                            .then(res => { // then print response status
                                console.log("res: ", res)
                                console.log("deck_id", res, res.deck.type, res.deck._id)
                                this.props.history.push(`/decks/${res.deck._id}`)
                                //console.log(res.statusText)
                            })
                            .catch(e => console.log("Error", e))
                    }
                })
                .catch(e => console.log("Error :", e))
        } else {
            this.setState({
                isLoading: true
            })
            this.state.service.publishDeck(this.state.fileLocation, this.state.thumbnail, this.state.title, this.state.author, this.state.type)
                .then(res => { // then print response status
                     console.log("res from Publish Deck: ",res)
                    console.log("deck_id", res, res.deck.type, res.deck._id)
                    this.props.history.push(`/decks/${res.deck._id}`)
                    //console.log(res.statusText)
                })
                .catch(e => console.log("Error", e))
        }
    }

    render() {

        return (
            <>
                <ToastContainer />
                <div className="add-deck-popup-wrapper">
                    <div className="add-deck-popup-container">
                        <div className="title-row"><h4>Add a new deck</h4>
                            <img src="/images/close-x.svg" alt="close popup" onClick={this.props.hide} />
                        </div>
                        {
                            this.state.isLoading
                                ? <div className="add-deck-spinner"><Spinner /></div>
                                : <form onSubmit={this.onClickHandler}>
                                    <div className="add-deck-popup-scrollable-form-wrapper">
                                        <div className="form-row">
                                            <div className="form-info">
                                                <h5>File</h5>
                                                <p className="text-s grey">Choose a PDF to upload.</p>
                                                {/* <div className="info-panel">
            <div className="info-panel-row"><img src="/images/pdf.svg" alt="PDF" /><div className="info-panel-heading">PDF only</div></div>
            <div className="info-panel-row"><div className="deck-label-free">Free</div><p className="text-s grey">max. 10 MB</p></div>
        <hr />
        <div className="info-panel-row"><div className="deck-label-pro">PRO</div><p className="text-s grey">max. 50 MB</p></div>
        </div> */}
                                            </div>
                                            <div className="form-content">
                                                <input type="file" name="upload" id="upload" onChange={this.onFileChange} accept=".pdf, application/pdf" />
                                            </div>
                                        </div>

                                        <div className="form-row top-line">
                                            <div className="form-info">
                                                <h5>Info</h5>
                                                <p className="text-s grey">Title and author name will be visible to your audience on the download page.</p>
                                            </div>
                                            <div className="form-content-info">

                                                <label htmlFor="presentation_title" className="add-deck-form-label">Presentation title</label>
                                                <input type="text" placeholder="Title of your presentation" id="presentation_title" name="title" value={this.state.title} onChange={e => this.onChangeHandler(e)} required />

                                                <label htmlFor="author_name" className="add-deck-form-label">Author name</label>
                                                <input type="text" placeholder="Author name for your presentation" id="author_name" name="author" value={this.state.author} onChange={e => this.onChangeHandler(e)} required />

                                            </div>
                                        </div>

                                        <div className="form-row top-line">
                                            <div className="form-info">
                                                <h5>Type</h5>
                                                <p className="text-s grey">Select whether you want to share your deck as a Free or PRO deck.</p> <p className="text-link orange text-s">Buy credits</p>


                                            </div>
                                            <div className="form-content">
                                                <div className="type-choices-container">
                                                    <label htmlFor="free" className="type-choice-wrapper">
                                                        <span className="form-radios-row"><input type="radio" id="free" name="type" value="Free" onChange={e => this.onChangeHandler(e)} required />
                                                            <div className="deck-label-free">Free</div></span>
                                                        <hr />
                                                        <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">5 MB file size limit <span className="feature-tooltiptext">How large your uploaded file may be</span></p></span>
                                                        <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">3 days online availability <span className="feature-tooltiptext">How long your file will be downloadable</span></p></span>
                                                        <span className="feature-row"><img src="/images/feature-cross.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">No custom branding <span className="feature-tooltiptext">Show your logo on the download page</span></p></span>
                                                        <span className="feature-row"><img src="/images/feature-cross.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">No CSV download <span className="feature-tooltiptext">Ability to export contacts as CSV</span></p></span>
                                                    </label>


                                                    <label htmlFor="pro" className="type-choice-wrapper">
                                                        <span className="form-radios-row"><input type="radio" id="pro" name="type" value="Pro" onChange={e => this.onChangeHandler(e)} required />
                                                            <div className="deck-label-pro">Pro</div></span>
                                                        <hr />
                                                        <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">50 MB file size limit <span className="feature-tooltiptext">How large your uploaded file may be</span></p></span>
                                                        <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">30 days online availability <span className="feature-tooltiptext">How long your file will be downloadable</span></p></span>
                                                        <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">Custom branding <span className="feature-tooltiptext">Show your logo on the download page</span></p></span>
                                                        <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">CSV download <span className="feature-tooltiptext">Ability to export contacts as CSV</span></p></span>
                                                    </label>

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="final-row">
                                        <div className="bt-standard-ghost" onClick={this.props.hide} >Cancel</div>
                                        <input type="submit" value="Publish" className="bt-standard" disabled={!this.state.publish} />
                                    </div>
                                </form>
                        }


                    </div>
                </div>
            </>
        )
    }
}
