import React, { useEffect, useState } from 'react'
import './deckdetails.css'
import Navigation from './Navigation';
import { Link, Redirect } from 'react-router-dom';
import ContactList from './ContactList';
import DeckService from '../service/DeckService';
import StatusDisplay from './StatusDisplay';
import ChangeStatusModal from "./ChangeStatusModal";
import useChangeStatusModal from './useChangeStatusModal';
import moment from 'moment';

export default function DeckDetails(props) {
    const { isShowing, toggle } = useChangeStatusModal();
    const [currentDeck, setCurrentDeck] = useState('')
    useEffect(() => {
        const service = new DeckService()
        service.fetchDeck(props.match.params.deckId)
            .then(res => {
                //   console.log("response from api", res)
                setCurrentDeck(res)
            })
    }, [props.match.params.deckId])

    const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
        // console.log("JSONData", JSONData)
        //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
        var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;

        var CSV = '';
        //Set Report title in first row or line

        CSV += ReportTitle + '\r\n\n';

        //This condition will generate the Label/Header
        if (ShowLabel) {
            let row = "";

            //Now convert each value to string and comma-seprated
            row += 'Name, Email,';

            row = row.slice(0, -1);

            //append Label row with line break
            CSV += row + '\r\n';
        }

        //1st loop is to extract each row
        for (let i = 0; i < arrData.length; i++) {
            let row = "";

            //2nd loop will extract each column and convert it in string comma-seprated
            // console.log("arrData[i][index] ", arrData[i].email, arrData[i].name)

            row += '"' + arrData[i].name + '","' + arrData[i].email + '",';

            row.slice(0, row.length - 1);

            //add a line break after each row
            CSV += row + '\r\n';
        }

        if (CSV === '') {
            alert("Invalid data");
            return;
        }

        //Generate a file name
        var fileName = "MyReport_";
        //this will remove the blank-spaces from the title and replace it with an underscore
        fileName += ReportTitle.replace(/ /g, "_");

        //Initialize file format you want csv or xls
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension    

        //this trick will generate a temp <a /> tag
        var link = document.createElement("a");
        link.href = uri;

        //set the visibility hidden so it will not effect on your web-layout
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";

        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    //This block copies the Download URL to the clipboard upon clicking the COPY button
    function copyURL() {
        var copyText = document.getElementById("downloadlink");
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/
        /* Copy the text inside the text field */
        document.execCommand("copy");
        /* Alert the copied text */
        alert("Copied your deck's download page link: " + copyText.value);
    }

    // This block filters the deck with the deck ID from the URL
    // console.log(props.match.params.deckId)
    // const [currentDeck] = decks.filter(deck => deck._id === props.match.params.deckId);
    // console.log(currentDeck)
    // console.log("currentDeck.contacts ", currentDeck.contacts)
    return (
        props.isUserLoggedIn 
        ?   <div className="main-wrapper">
                <Navigation />
                <div className="workspace-spacer">
                    <div className="workspace-wrapper">

                        <div className="deckdetails-title-wrapper">
                            {/* <Link className="deckdetails-navlink bt-standard-ghost" to="/decks"><img src="/images/arrow-back.svg" alt="" />Back to all decks</Link> */}
                            <Link className="deckdetails-navlink" to="/decks"><img src="/images/arrow-back.svg" alt="" /><p className="text-bt orange no-margins">Back to all decks</p></Link>
                            <h4>{currentDeck ? currentDeck.title : "Loading"}</h4>
                            <div className="deckdetails-title-contacts-wrapper">

                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="11" stroke="#8B8B9C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M19 19.9907C18.2836 18.7749 17.2604 17.767 16.0319 17.0669C14.8035 16.3669 13.4126 15.9991 11.9973 16C10.582 16.0009 9.19163 16.3706 7.96414 17.0723C6.73665 17.774 5.71478 18.7833 5 20" stroke="#8B8B9C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 13C14.2091 13 16 11.2091 16 9C16 6.79086 14.2091 5 12 5C9.79086 5 8 6.79086 8 9C8 11.2091 9.79086 13 12 13Z" stroke="#8B8B9C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>



                                <p className="grey no-margins text-s">{currentDeck ? currentDeck.contacts.length : "No "} Contacts</p></div>

                        </div>
                        <div className="workspace-main-content">

                            <ChangeStatusModal
                                props={props}
                                isShowing={isShowing}
                                hide={toggle}
                                deck={currentDeck}
                                updateDeck={setCurrentDeck}
                            />

                            <div className="form-row form-row top-line">
                                <div className="status-block">
                                    <h5>Status</h5>
                                    <StatusDisplay expiryDate={currentDeck.expiryDate} showExplanation={true} />
                                </div>
                                <div className="form-content">
                                    <div className="deck-thumbnail-block-wrapper">
                                        {/* <img src={`https://slides-fox-files.s3.eu-central-1.amazonaws.com/${currentDeck._id}-thumbnail.jpg`} alt={currentDeck.title} className="deck-thumbnail-large" /> */}
                                        <img src={`${currentDeck.thumbnail}.jpg`} alt={currentDeck.title} className="deck-thumbnail-large" />

                                        <div className="change-status-block">
                                            {
                                                //This block checks if the deck has expired. No - Archive, Yes - Re-activate
                                                moment(currentDeck.expiryDate).isBefore(moment())

                                                    ? <><button className="bt-outline" onClick={toggle}>Re-activate</button>
                                                        <p className="text-s grey">You can re-activate your slide deck again, meaning your audience can download page goes online again and your audience can access the slide deck again.</p>
                                                    </>
                                                    : <><button className="bt-outline" onClick={toggle}>Archive</button>
                                                        <p className="text-s grey">You can archive your deck, meaning it will no longer be available for download by your audience.</p>
                                                    </>
                                            }



                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-row top-line">
                                <div className="status-block">
                                    <h5>Sharing</h5>
                                    <p className="text-s grey">Share your slide deck with your audience by sending them to the download page for this deck.</p>
                                </div>
                                <div className="form-content">
                                    <div className="qr-block-wrapper">
                                        <div className="qr-block-qr-row">
                                            <div className="qr-wrapper">
                                                <img src={`${currentDeck.qrcode}`} alt={`QR code for ${currentDeck.title}`} />
                                            </div>
                                            <div className="qr-block-instructions">
                                                <img src="/images/instructions-qr1.png" alt="" />
                                                <p>Show this QR code <br /> to your audience</p>
                                                <a
                                                    href={`https://api.slidesfox.nl/decks/${currentDeck._id}/qr`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    download={true}>
                                                    <button className="bt-standard">
                                                        Download QR code
                                                    </button>
                                                </a>

                                                <p className="text-s grey">Or right click on the QR code and then select "Save as".</p>
                                            </div>
                                        </div>
                                        <div className="qr-block-download-link">
                                            <h5>Download page link</h5>
                                            <div className="qr-download-link-wrapper">
                                                <input type="text" name="downloadlink" id="downloadlink" readOnly value={"app.slidesfox.nl/decks/" + currentDeck._id + "/download"} />
                                                <button className="bt-standard" onClick={copyURL} >Copy</button>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                            <div className="form-row top-line">
                                <div className="status-block">
                                    <div className="status-block-row"><h5>Contacts</h5><p className="grey">({currentDeck ? currentDeck.contacts.length : "No "})</p></div>
                                    <p className="text-s grey">Here you see all people who registered to download your deck.</p>

                                    {(currentDeck.type === "Pro" && !(currentDeck.contacts === undefined) && currentDeck.contacts.length > 0)
                                        ? <button className="bt-standard-ghost csv-btn" onClick={() => JSONToCSVConvertor(currentDeck.contacts, currentDeck.title, true)}><img src="/images/download-icon.svg" alt="Download" /> Download .CSV</button>
                                        : null
                                    }

                                </div>
                                <div className="form-content">

                                    {(currentDeck.contacts === undefined || currentDeck.contacts.length === 0)

                                        ? <div className="empty-contacts-wrapper">
                                            <div className="empty-contacts">
                                                <img src="/images/no-contacts.png" alt="No contacts yet" />
                                                <h4>No contacts yet</h4>
                                                <b className="grey text-s">Show the QR code to people to send them to this deck's download page.</b>
                                            </div>
                                        </div>

                                        : <ContactList props={currentDeck.contacts} />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        : <Redirect to="/" /> 
    )
}
