import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import './signup.css';

export default class ResetPassword extends Component {
    render() {
        return (
            this.props.isUserLoggedIn 
            ? <div className="window-wrapper">
                    <Link to="/"><img src="/images/logo-dark-v1.svg" alt="slides fox" className="logo-s1"/></Link>
                    <div className="window">
                        <h3>Reset password</h3>
                        <p className="grey">Enter your e-mail address to reset your password.</p>
                        <form action="" className="signup-form">
                            <input type="text" name="email" placeholder="Your e-mail" />
                            <input type="submit" value="Reset password" className="bt-standard" />
                        </form>
                    </div>
                </div>
            : <Redirect to="/" /> 
        )
    }
}
