import axios from 'axios'

class UploadService {
  constructor() {
    this.service = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      withCredentials: true
    })
  }

  user = JSON.parse(localStorage.getItem('@pocpassport'))

  uploadDeck = (deck) => {
    console.log("deck", deck)
    return this.service({url: '/upload/deck-upload' , 
      method: 'POST',
      headers: { 'Authorization' : this.user.token,
                 'content-type': `multipart/form-data; boundary=${deck._boundary}`}, 
      data: deck
    } )
    .then(response => response.data)
  }

  publishDeck = (fileLocation, thumbnail, title, author, type) => {
    console.log("In Publish deck")
    return this.service({
      method: 'POST',
      url: '/decks' , 
      headers: { Authorization: this.user.token},
      data: { file: fileLocation, thumbnail, title, author, type}
    })
    .then(response => response.data)
  }

  uploadProfilePicture = (profilePicture) => {
    return this.service({
      method: 'POST',
      url:'/upload/profilepicture',
      headers: { 'Authorization': this.user.token ,
        'content-type': `multipart/form-data; boundary=${profilePicture._boundary}`},
      data: profilePicture
    })
    .then(response => response.data)
  }
  uploadLogo = (logo) => {
    return this.service({
      method: 'POST',
      url:'/upload/logo',
      headers: { 'Authorization': this.user.token ,
        'content-type': `multipart/form-data; boundary=${logo._boundary}`},
      data: logo      
    })
    .then(response => response.data)
  }
}

export default UploadService