import React, { Component } from 'react'
import Navigation from './Navigation'
import { Redirect } from 'react-router-dom'

export default class Contact extends Component {
    render() {
        return (
            this.props.isUserLoggedIn
            ?   <div className="main-wrapper">
                    <Navigation />
                    <div className="workspace-spacer">
                        <div className="workspace-wrapper">
                            <div className="workspace-title-wrapper"><h4>Contact</h4><button className="bt-standard"><img src="/images/plus.svg" alt="Upload" />Buy credits</button></div>
                            <div className="workspace-main-content">
                            </div>
                        </div>
                    </div>
                </div>
            : <Redirect to="/" /> 
        )
    }
}
