import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './signup.css';

export default class Signup extends Component {
    render() {
        return (
            <div className="window-wrapper">
                <Link to="/"><img src="/images/logo-dark-v1.svg" alt="slides fox" className="logo-s1" /></Link>
                <div className="window">
                    <h3>Sign up</h3>
                    <p className="grey">Already have an account? <Link to="/login" className="orange text-link">Log in</Link></p>
                    <button className="bt-standard">
                        <img src="./images/google-icon.svg" alt="Google" />Continue with Google</button>
                    <hr />
                    <p className="grey text-caps-muted">or</p>
                    <Link to="/signup-email"><button className="bt-standard">Sign up with e-mail</button></Link>
                </div>
            </div>
        )
    }
}
