import React from 'react'
import { NavLink } from 'react-router-dom'
import './Navigation.css'
import AuthService from '../service/AuthService';
import { useEffect, useState } from 'react';

export default function Navigation() {

    const [ userCredits, setUserCredits ] = useState(0)
    useEffect(() => {
        const service = new AuthService()
        service.isAuthenticated()
            .then(res => {
                console.log("response from api", res)
                setUserCredits(res.credits)
            })
    }, [])
    return (
        <nav>
            <div className="nav-top">
                <img src="/images/logo-dark-v1.svg" alt="slides fox" className="logo-sm" />
                <div className="nav-main">
                    <NavLink to="/decks" activeClassName="active-navlink" className="nav-item"><img src="/images/decks.svg" alt="My decks" /> My decks</NavLink>
                    <NavLink to="/archive" activeClassName="active-navlink" className="nav-item"><img src="/images/archive.svg" alt="Archive" />Archive</NavLink>                
                </div>
            </div>
            <div className="nav-bottom">
                <NavLink to="/account" activeClassName="active-navlink" className="nav-item"><img src="/images/account.svg" alt="Account" />Account</NavLink>

                <div className="credits-wrapper">
                    <img src="/images/coin.svg" alt="Credits" />
                    <p className="white">{userCredits} Credits</p>
                    <p className="text-tiny white">Buy and redeem credits to unlock PRO features, such as larger file size limits for your decks.</p>
                    <NavLink to="/credits"><p className="text-s orange _600">Buy and redeem credits</p></NavLink>
                </div>
                <hr />
                <NavLink to="/contact" activeClassName="active-navlink" className="nav-item"><img src="/images/contact.svg" alt="Contact" />Contact</NavLink>
                <NavLink to="/faq" activeClassName="active-navlink" className="nav-item"><img src="/images/faq.svg" alt="FAQ" />FAQ</NavLink>
            </div>
        </nav>
    )
}
