import React from 'react'
import Navigation from './Navigation'
import './decks.css'
import AddDeckModal from "./AddDeckModal";
import useAddDeckModal from './useAddDeckModal';
import DecksList from './DecksList';
import { Redirect } from 'react-router-dom';

export default function Decks(props) {
    console.log("in Decks", props)
    const { isShowing, toggle } = useAddDeckModal();
    return (
        props.isUserLoggedIn 
        ?   <div className="main-wrapper">
                <Navigation />
                <div className="workspace-spacer">
                    <div className="workspace-wrapper">
                        <div className="workspace-title-wrapper"><h4>My decks</h4><button className="bt-standard" onClick={toggle}><img src="/images/plus.svg" alt="Upload" /> Upload new deck</button></div>
                        <div className="workspace-main-content">

                            <AddDeckModal
                                isShowing={isShowing}
                                hide={toggle}
                                props={props}
                            />

                            <DecksList active={true} />

                        </div>
                    </div>
                </div>
            </div>
        :  <Redirect to="/" /> 
    )
}