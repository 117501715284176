import React, { useEffect, useState } from 'react'
import './download.css'
import { Link } from 'react-router-dom'
import DeckService from '../service/DeckService';
import ContactService from '../service/ContactService';
import moment from 'moment'
import { toast, ToastContainer } from 'react-toastify';
import './toast-styling.css';

export default function Download(props) {

  const contactservice = new ContactService()
  const [currentDeck, setCurrentDeck] = useState('')
  const [contactName, setContactName] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [deckExpired, setDeckExpired] = useState(false)

  useEffect(() => {
    const deckservice = new DeckService()
    deckservice.fetchDeckPublic(props.match.params.deckId)
      .then(res => setCurrentDeck(res))
      .catch(e => console.log("Error ", e))
  }, [props.match.params.deckId])

  useEffect(() => {
    console.log("expiryDate", currentDeck.expiryDate)
    if (moment(currentDeck.expiryDate).isBefore(moment())) {
      // console.log("deckExpired before:", deckExpired)
      setDeckExpired(true)
      // console.log("deckExpired after:", deckExpired)
    } else {
      setDeckExpired(false)
    }
  }, [currentDeck])

  const onChangeHandler = (e) => {
    const { name, value } = e.target
    if (name === 'name') {
      setContactName(value)
    }
    if (name === 'email') {
      setContactEmail(value)
    }
  }

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const emailRegex = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const nameRegex = RegExp(/^[a-zA-Z\s]*$/);
    if (!emailRegex.test(contactEmail)) {
      toast.error(<><img src="/images/toastify-cross.svg" alt="Something went wrong" />Invalid e-mail</>);
    } else if (!nameRegex.test(contactName)) {
      toast.error(<><img src="/images/toastify-cross.svg" alt="Something went wrong" />Invalid name</>);
    }
    else {
      contactservice.createContact(props.match.params.deckId, contactName, contactEmail)
        .then(() => {
          setSubmitSuccess(true)
        })
        .catch(e => console.log("Error", e))
    }
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="download-wrapper">

        {currentDeck.logo ?
          <div className="download-logo-wrapper">
            <img src={`${currentDeck.logo}`} alt="" />
          </div>
          :
          <div className="download-logo-wrapper">
            <img src="/images/logo-dark-v1.svg" alt="" />
          </div>
          }

        {
              <>
                {!deckExpired && !submitSuccess &&
                  <>
                    <div className="download-deck-hero">
                      <h3>Download this slide deck</h3>
                      <div className="download-thumbnail-spacer">
                        <div className="download-thumbnail-wrapper">
                          {/* <img src="/images/dummy-thumbnail4.png" alt="Deck thumbnail" /> */}
                          <img src={`${currentDeck.thumbnail}.jpg`} alt="Deck thumbnail" />
                        </div>
                      </div>
                      <h4>{currentDeck.title}</h4>
                      {/* <p className="grey">John Johanson</p> */}
                      <p className="grey">{currentDeck.author}</p>
                    </div>
                    <form
                      action=""
                      className="download-form"
                      onSubmit={(e) => onSubmitHandler(e)}
                    >
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={contactName}
                        placeholder="Your name"
                        onChange={(e) => onChangeHandler(e)}
                        required
                      />
                      <input
                        type="text"
                        name="email"
                        id="email"
                        value={contactEmail}
                        placeholder="Your email address"
                        onChange={(e) => onChangeHandler(e)}
                        required
                      />
                      <div className="download-note">
                        <img src="/images/clock.svg" alt="Note" />
                        <p className="text-s grey">
                          You will receive the download link within an hour in your
                          inbox.
                </p>
                      </div>
                      <div className="download-form-row">
                        <input type="checkbox" name="tac" id="tac" required />
                        <label htmlFor="tac">
                          I have understood and I agree to the{" "}
                          <Link to="#" className="text-link s">
                            terms & conditions
                  </Link>
                  .
                </label>
                      </div>
                      <div className="download-form-row">
                        <input type="checkbox" name="privacy" id="privacy" required />
                        <label htmlFor="privacy">
                          I consent that my name and e-mail will be shared with the
                  presentation's author ({currentDeck.author}).
                </label>
                      </div>
                      <button className="bt-standard">Submit</button>
                    </form>
                    <div className="download-footer">
                      <p className="text-s grey">powered by</p>
                      <img src="/images/logo-dark-v1.svg" alt="powered by slides fox" />
                    </div>
                  </>
                }
              </>
            }

            {
              <>
                {!deckExpired && submitSuccess &&
                  <div className="download-message">
                    <img src="/images/success.svg" alt="Thank you" />
                    <h4 className="success-color">Thank you</h4>
                    <p className="grey">
                      You have succesfully signed up to download the deck.
              </p>

                    <div className="download-note">
                      <img src="/images/clock.svg" alt="Note" />
                      <p className="text-s grey">
                        You will receive the download link within an hour in your
                        inbox. Also check your spam folder.
                </p>
                    </div>

                    <p className="text-s grey">Now enjoy the presentation.</p>

                    <div className="download-footer">
                      <p className="text-s grey">powered by</p>
                      <img
                        src="/images/logo-dark-v1.svg"
                        alt="powered by slides fox"
                      />
                    </div>
                  </div>}
              </>
            }

            {
              <>
                {deckExpired &&
                  <div className="download-message">
                    <img src="/images/error.svg" alt="Thank you" />
                    <h4>This deck is no longer available for download.</h4>
                    <p className="grey">
                      You can contact the author and ask to re-activate it again.
              </p>
                  </div>}
              </>
            }
          </div>
    </>
  );
}
