import React from 'react'
import './deckdetails.css'
import moment from 'moment';

export default function StatusDisplay(props) {
    let color, text, message;
    let noOfDays = moment(props.expiryDate).diff(moment(), 'days')
    let noOfSeconds = moment(props.expiryDate).diff(moment(), 'seconds')

    switch (true) {
        case (noOfDays >= 5):
            color = 'green';
            text = `expires in ${noOfDays} days`
            message = 'Your presentation is uploaded succesfully and can be accessed by your audience.'
            break;

        case (noOfDays < 5 && noOfDays >= 3):
            color = 'yellow';
            text = `expires in ${noOfDays} days`
            message = 'Your presentation is uploaded succesfully and can be accessed by your audience.'
            break;

        case ((noOfDays < 3 && noOfDays > 0) || (noOfDays === 0 && noOfSeconds > 0)):
            color = 'red';
            text = `expires ${moment(props.expiryDate).fromNow()}`
            message = `Your presentation is uploaded succesfully and can be accessed by your audience, but it will be archived soon.`
            break;

        default: {
            color = 'grey';
            text = `expired ${moment(props.expiryDate).fromNow()}`
            message = 'Your presentation has been archived and is no longer accessible by your audience.'
            break;
        }
    }

    return (
        <>
            {
                props.showExplanation
                    ? <div><div className={`deck-status-box-${color}`}> <div className={`deck-status-light-${color}`}></div>{text}</div>
                        <p className="text-s grey">{message}</p></div>
                    : <div><div className={`deck-status-box-${color}`}> <div className={`deck-status-light-${color}`}></div>{text}</div></div>
            }
        </>
    )
}
