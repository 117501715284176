import React from 'react'
import './styleguide.css';
import { Redirect } from 'react-router-dom';

export default function Styleguide(props) {
    return (
        props.isUserLoggedIn
        ?   <div className="style-wrapper">
                <span className="display1">Display 1</span><br />
                <p className="style-label">class display1</p>

                <hr />
                <span className="display2">Display 2</span>
                <p className="style-label">class display2</p>

                <hr />
                <h1>Heading 1</h1>
                <p className="style-label">h1</p>

                <hr />
                <h2>Heading 2</h2>
                <p className="style-label">h2</p>

                <hr />
                <h3>Heading 3</h3>
                <p className="style-label">h3</p>

                <hr />
                <h4>Heading 4</h4>
                <p className="style-label">h4</p>

                <hr />
                <h5>Heading 5</h5>
                <p className="style-label">h5</p>

                <hr />
                <h6>Heading 6</h6>
                <p className="style-label">h6</p>

                <hr />
                <p>Body Text</p>
                <p className="style-label">p</p>

                <hr />
                <p className="text-link">Body Text Link</p>
                <p className="style-label">class text-link</p>

                <hr />
                <p className="text-link-underlined">Body Text Underlined Link</p>
                <p className="style-label">class text-link-underlined</p>

                <hr />
                <p className="text-s">Small Text</p>
                <p className="style-label">class text-s</p>

                <hr />
                <p className="text-bt">Button Text</p>
                <p className="style-label">class text-bt</p>

                <hr />
                <p className="text-s-muted">Small Text Muted</p>
                <p className="style-label">class text-s-muted</p>

                <hr />
                <p className="text-tiny">Tiny text</p>
                <p className="style-label">class text-tiny</p>

                <hr />
                <p className="text-s-caps">Small caps</p>
                <p className="style-label">class text-s-caps</p>

                <hr />
                <p className="text-caps-muted">Small caps muted</p>
                <p className="style-label">class text-caps-muted</p>

                <hr />
                <button className="bt-s">small button</button>
                <p className="style-label">class bt-s</p>

                <hr />
                <button className="bt-standard">standard button</button>
                <p className="style-label">class bt-standard</p>

                <hr />
                <button className="bt-large">large button</button>
                <p className="style-label">class bt-large</p>

                <hr />
                <button className="bt-outline">outline</button>
                <p className="style-label">class bt-outline</p>

                <hr />
                <label>Name</label>            
                <p className="style-label">label</p>

                <hr />
                <input type="text" name="name" placeholder="lasdasd" />
                <p className="style-label">input type="text"</p>

            </div>
        : <Redirect to="/" /> 
    )
}
