import React from 'react'
import { Link } from 'react-router-dom'
import './home.css'

export default function Home() {
    return (
//         <div>
//             {/* <div data-collapse="medium" data-animation="default" data-duration={400} data-easing="ease-out-expo" data-easing2="ease-out-expo" role="banner" className="pk-navbar-2 w-nav">
//           <div className="nav_position">
//             <div className="nav_inner"><Link href="#" className="nav-item nav-logo w-inline-block"><img src="/images/landing/Light.svg" width={227} alt="" className="pk_logo-img slidesfox" /></Link>
//               <nav role="navigation" className="structure-project-menu-2 w-nav-menu">
//                 <div className="structure-menu-items">
//                   <Link href="#" className="nav-item-sf w-inline-block">
//                     <div className="nav-link-2">Features</div>
//                   </Link>
//                   <Link href="#" className="nav-item-sf w-inline-block">
//                     <div className="nav-link-2">About</div>
//                   </Link>
//                   <Link href="#" className="nav-item-sf w-inline-block">
//                     <div className="nav-link-2">Pricing</div>
//                   </Link>
//                   <div className="menu-btn-wrapper"><Link href="#" className="button-5 button-link slidesfox w-button">log in</Link><Link href="#" className="button-5 outline navbar-sf w-button">sign up</Link></div>
//                 </div>
//                 <div className="wrap _w-custom structure-menu-wrap w-hidden-main">
//                   <Link href="#" className="nav-item w-inline-block">
//                     <div className="nav-link-2">Features</div>
//                   </Link>
//                   <Link href="#" className="nav-item w-inline-block">
//                     <div className="nav-link-2">About</div>
//                   </Link>
//                   <Link href="#" className="nav-item w-inline-block">
//                     <div className="nav-link-2">Pricing</div>
//                   </Link>
//                   <div className="menu-btn-wrapper mobile"><Link href="#" className="button-5 button-link slidesfox w-button">log in</Link><Link href="#" className="button-5 outline navbar-sf mobile w-button">sign up</Link></div>
//                 </div>
//               </nav>
//               <div className="structure-cards2-menu-btn w-nav-button"><img src="/images/landing/icon_nav.svg" alt="" className="structurenav-menu" /></div>
//             </div>
//           </div>
//         </div> */}
//             <div className="section_hero-2">
//                 <div className="pk_container">
//                     <div className="hero_content slidesfox w-clearfix">
//                         <div className="wrapper_hero-text-2 slidesfox">
//                             <h1 className="landing-title our-solution white">Simple slide sharing and follow up. </h1>
//                             <p className="subtitle-2 hero-subtitle slidesfox">Share directly and collect audience information.</p>
//                             <div className="hero_btn-row"><Link to="/signup" className="bt-standard">Sign up</Link>
//                                 <Link to="/login" className="bt-standard">Log in</Link></div>
//                         </div>
//                         <div className="hero_image slidesfox"><img src="/images/landing/illustration_hero-main2x.png" srcSet="/images/landing/illustration_hero-main2x-p-500.png 500w, /images/landing/illustration_hero-main2x-p-800.png 800w, /images/landing/illustration_hero-main2x-p-1080.png 1080w, /images/landing/illustration_hero-main2x-p-1600.png 1600w, /images/landing/illustration_hero-main2x.png 1848w" sizes="(max-width: 767px) 100vw, (max-width: 991px) 450px, 55vw" alt="" /></div><img src="/images/landing/illustration_hero-main2x.png" srcSet="/images/landing/illustration_hero-main2x-p-500.png 500w, /images/landing/illustration_hero-main2x-p-800.png 800w, /images/landing/illustration_hero-main2x-p-1080.png 1080w, /images/landing/illustration_hero-main2x-p-1600.png 1600w, /images/landing/illustration_hero-main2x.png 1848w" sizes="(max-width: 479px) 90vw, (max-width: 767px) 400px, 100vw" alt="" className="hero_image mobile slidesfox" /></div>
//                 </div>
//             </div>
//             <div className="section-2">
//                 <div className="pk_container">
//                     <div className="content_wrapper _2col_block">
//                         <div className="content_col text_aboutus">
//                             <h2>Yes, you will share these slides (fully automated of course).<br /></h2>
//                             <p className="body-6 highlight section_subtitle">Sharing your slides used to be a chore. Now it's simple and a beautiful opportunity to stay in touch with your audience.</p><img src="/images/landing/illustration_section-main2x.png" srcSet="/images/landing/illustration_section-main2x-p-500.png 500w, /images/landing/illustration_section-main2x-p-800.png 800w, /images/landing/illustration_section-main2x.png 1001w" sizes="(max-width: 479px) 202.625px, (max-width: 767px) 270.171875px, (max-width: 991px) 324.203125px, 440px" alt="" className="section-image conference slidesfox" /></div>
//                         <div className="content_col right">
//                             <div className="features_wrapper list">
//                                 <div className="box_wrapper-3 list"><img src="/images/landing/icon_sidebar-medium2x.png" alt="" className="feature_image small list" />
//                                     <div className="box_text left-align list">
//                                         <h3>Know your audience</h3>
//                                         <p className="body-6 feature-subtext left-align">Identify your audience members by name and e-mail address.</p>
//                                     </div>
//                                 </div>
//                                 <div className="box_wrapper-3 list"><img src="/images/landing/icon_sidebar-medium-22x.png" alt="" className="feature_image small list" />
//                                     <div className="box_text left-align list">
//                                         <h3>Send follow-ups</h3>
//                                         <div className="body-6 feature-subtext left-align">Connect with your audience afterwards by e-mail or through social media.</div>
//                                     </div>
//                                 </div>
//                                 <div className="box_wrapper-3 list"><img src="/images/landing/icon_sidebar-medium-32x.png" alt="" className="feature_image small list" />
//                                     <div className="box_text left-align list">
//                                         <h3>No more silly pictures</h3>
//                                         <div className="body-6 feature-subtext left-align">Save your audience from photographing your slides. It's 2020 after all!</div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="section lightgreybg">
//                 <div className="pk_container">
//                     <div className="content_wrapper">
//                         <div className="title_wrapper _70">
//                             <h2>Simple for you, nice for your audience.<br /></h2>
//                             <p className="body-7 highlight section_subtitle">We have simplified the entire process of sharing your slides and automated the tedious bits, so you can focus on what matters:&nbsp;Building relationships with your audience.</p>
//                         </div>
//                         <div className="features_wrapper slidesfox"><img src="/images/landing/arrow-col4.svg" alt="" className="arrow long col4" />
//                             <div className="features_wrapper timeline _4col">
//                                 <div className="box_wrapper-4 step col4"><img src="/images/landing/icon_medium_44.png" alt="" className="feature_image medium steps-under _2" />
//                                     <div className="box_text">
//                                         <h3>Upload your slides<br /></h3>
//                                         <p className="body-7 feature-subtext">Upload your presentation as a simple PDF and you are ready to go.</p>
//                                     </div>
//                                 </div>
//                                 <div className="box_wrapper-4 step col4"><img src="/images/landing/illustration_large-01.png" alt="" className="feature_image medium steps-under _2" />
//                                     <div className="box_text">
//                                         <h3>Show the QR code<br /></h3>
//                                         <p className="body-7 feature-subtext">Lead your audience to a download page with a unique QR&nbsp;code.<br /></p>
//                                     </div>
//                                 </div>
//                                 <div className="box_wrapper-4 step col4"><img src="/images/landing/illustration_large-02.png" alt="" className="feature_image medium steps-under _2" />
//                                     <div className="box_text">
//                                         <h3>Wait for audience</h3>
//                                         <p className="body-7 feature-subtext">Your audience scans the code and enters their email to receive your slides.<br /></p>
//                                     </div>
//                                 </div>
//                                 <div className="box_wrapper-4 step col4 end"><img src="/images/landing/illustration_large-03.png" alt="" className="feature_image medium steps-under _2" />
//                                     <div className="box_text">
//                                         <h3>Collect contacts</h3>
//                                         <p className="body-7 feature-subtext">You receive the contact information for audience members.<br /></p>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="section-2">
//                 <div className="pk_container">
//                     <div className="title_wrapper _70">
//                         <h2>Hooray, it's not another subscription!<br /></h2>
//                         <p className="body-7 highlight section_subtitle">Try it for free and go pro on a pay-as-you-go basis. </p>
//                     </div>
//                     <div className="pricing-placeholder">





//                         <div className="credits-pricing-container">

//                             <div className="credits-pricing-tier-wrapper">
//                                 <div className="tier-highlight-bar">

//                                 </div>

//                                 <div className="tier-content">
//                                     <div className="tier-top-section">
//                                         <div className="deck-label-free">Free</div>
//                                         <h4>Starter</h4>
//                                         <p className="text-s grey">For small groups and simple presentations</p>
//                                         <p className="price">0 €</p>
//                                     </div>

//                                     <div className="tier-features">
//                                         <div className="no-of-decks"></div>
//                                         <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">5 MB file size limit <span className="feature-tooltiptext">How large your uploaded file may be</span></p></span>
//                                         <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">3 days online availability <span className="feature-tooltiptext">How long your file will be downloadable</span></p></span>
//                                         <span className="feature-row"><img src="/images/feature-cross.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">No custom branding <span className="feature-tooltiptext">Show your logo on the download page</span></p></span>
//                                         <span className="feature-row"><img src="/images/feature-cross.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">No CSV download <span className="feature-tooltiptext">Ability to export contacts as CSV</span></p></span>
//                                     </div>
//                                     <div className="tier-cta">
//                                     </div>
//                                 </div>


//                             </div>

//                             <div className="credits-pricing-tier-wrapper">
//                                 <div className="tier-highlight-bar popular">
//                                     popular
// </div>


//                                 <div className="tier-content popular-outline">
//                                     <div className="tier-top-section">
//                                         <div className="deck-label-pro">Pro</div>
//                                         <h4>Standard pack</h4>
//                                         <p className="text-s grey fixwidth">For most professional presenters</p>
//                                         <p className="price">6,99 €</p>
//                                         <p className="price-per-deck">1,39 € / deck</p>
//                                         <p className="grey text-tiny">one-time payment, billed once</p>
//                                     </div>

//                                     <div className="tier-features">
//                                         <div className="no-of-decks"><img src="/images/pricing-decks.svg" alt="" />5 slide decks</div>
//                                         <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">50 MB file size limit <span className="feature-tooltiptext">How large your uploaded file may be</span></p></span>
//                                         <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">30 days online availability <span className="feature-tooltiptext">How long your file will be downloadable</span></p></span>
//                                         <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">Custom branding <span className="feature-tooltiptext">Show your logo on the download page</span></p></span>
//                                         <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">CSV download <span className="feature-tooltiptext">Ability to export contacts as CSV</span></p></span>

//                                     </div>
//                                     <div className="tier-cta">
//                                         <div className="bt-s">Buy standard pack</div>
//                                     </div>
//                                 </div>

//                             </div>

//                             <div className="credits-pricing-tier-wrapper">
//                                 <div className="tier-highlight-bar">

//                                 </div>

//                                 <div className="tier-content">
//                                     <div className="tier-top-section">
//                                         <div className="deck-label-pro">Pro</div>
//                                         <h4>Value pack</h4>
//                                         <p className="text-s grey">For professionals with many presentations</p>
//                                         <p className="price">9,99 €</p>
//                                         <p className="price-per-deck">0,99 € / deck</p>
//                                         <p className="grey text-tiny">one-time payment, billed once</p>
//                                     </div>

//                                     <div className="tier-features">
//                                         <div className="no-of-decks"><img src="/images/pricing-decks.svg" alt="" />10 slide decks</div>
//                                         <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">50 MB file size limit <span className="feature-tooltiptext">How large your uploaded file may be</span></p></span>
//                                         <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">30 days online availability <span className="feature-tooltiptext">How long your file will be downloadable</span></p></span>
//                                         <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">Custom branding <span className="feature-tooltiptext">Show your logo on the download page</span></p></span>
//                                         <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">CSV download <span className="feature-tooltiptext">Ability to export contacts as CSV</span></p></span>

//                                     </div>
//                                     <div className="tier-cta">
//                                         <div className="bt-s">Buy value pack</div>
//                                     </div>
//                                 </div>

//                             </div>


//                         </div>





//                         {/* <div className="pricing-placeholder_block" />
//               <div className="pricing-placeholder_block" />
//               <div className="pricing-placeholder_block" /> */}
//                     </div>
//                     <div className="w-embed" />
//                 </div>
//             </div>
//             <div className="section-2">
//                 <div className="pk_container">
//                     <div className="orange-box_wrapper-2">
//                         <div className="orange-box_content">
//                             <div className="orange-box_top-row">
//                                 <div className="title_wrapper _50 _10marginbottom slidesfox">
//                                     <h5 className="whitetext-2 orangelabel">public beta special offer</h5>
//                                     <h2 className="white">Sign up now and receive three credits for free.<br /></h2>
//                                 </div><img src="/images/landing/illustration_cta2x.png" srcSet="/images/landing/illustration_cta2x-p-500.png 500w, /images/landing/illustration_cta2x-p-800.png 800w, /images/landing/illustration_cta2x.png 1550w" sizes="(max-width: 479px) 130px, (max-width: 767px) 150px, (max-width: 991px) 180px, 100vw" alt="" className="orange-box_img-mobile" /></div><Link to="#" className="button-5 white w-button">Get&nbsp;started</Link></div><img src="/images/landing/illustration_cta2x.png" srcSet="/images/landing/illustration_cta2x-p-500.png 500w, /images/landing/illustration_cta2x-p-800.png 800w, /images/landing/illustration_cta2x.png 1550w" sizes="(max-width: 991px) 100vw, 370px" alt="" className="orangebox-headphone_image slidefox" /></div>
//                 </div>
//             </div>
//             <div className="section-2 bordergrey">
//                 <div className="pk_container">
//                     <div className="content_wrapper about_text">
//                         <div className="title_wrapper _70">
//                             <p className="body-7 highlight section_subtitle bottom">From the presentation nerds at smartvizual</p>
//                             <h3>We are a design boutique supplying teams with visuals that bring their ideas and services to life. You can find us in Amsterdam.</h3>
//                         </div>
//                         <Link to="#" className="button-link-2 w-inline-block">
//                             <div>Learn more about us</div>
//                             <div className="link-underline" />
//                         </Link>
//                     </div>
//                 </div>
//             </div>
//             <div className="section-2 footer">
//                 <div className="pk_container">
//                     <div className="footer_wrapper">
//                         <div className="footer_col-2 social_col"><img src="/images/landing/Light.svg" alt="" className="footer_logo slidesfox" />
//                             {/* <div className="footer_social-wrapper"><Link href="#" className="pk-footer-social-2 w-inline-block"><img src="/images/landing/twitter.svg" alt="" className="icon_social" /></Link><Link href="#" className="pk-footer-social-2 w-inline-block"><img src="/images/landing/instagram_white.svg" alt="" className="icon_social" /></Link><Link href="#" className="pk-footer-social-2 hide w-inline-block"><img src="/images/landing/facebook.svg" alt="" className="icon_social" /></Link><Link href="https://www.linkedin.com/company/smartvizual/" target="_blank" className="pk-footer-social-2 end w-inline-block"><img src="/images/landing/linkedin_white.svg" alt="" className="icon_social" /></Link></div> */}
//                             <p className="body-7 footer-copyright">©2020 smartvizual</p>
//                         </div>
//                         <div className="footer_navigation">
//                             <div className="footer_col-2">
//                                 <h5 className="footer-label-2">How to</h5><Link to="#" className="footer-link-2">Quick tips</Link><Link to="#" className="footer-link-2">Traveller's guide</Link></div>
//                             <div className="footer_col-2">
//                                 <h5 className="footer-label-2">Services</h5><Link to="#" className="footer-link-2">Presentation bootcamp</Link><Link to="#" className="footer-link-2">Slide agency</Link><Link to="#" className="footer-link-2">Shows &amp;&nbsp;events</Link></div>
//                             <div className="footer_col-2">
//                                 <h5 className="footer-label-2">Company</h5><Link to="#" className="footer-link-2">About us</Link><Link to="https://www.presentationfox.com/about-us#contactus" className="footer-link-2">Contact</Link><Link to="#" className="footer-link-2">Privacy policy</Link></div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>


        <div className="home-wrapper">
            <div className="home-temp-menu">
                <img src="/images/logo-light-v1.svg" alt="" />
                <Link to="/signup" className="bt-standard">Sign up</Link>
                <Link to="/login" className="bt-standard">Log in</Link>
            </div>
        </div>
    )
}
