import React, { useState, useEffect } from 'react'
import './decks.css'
import { Link } from 'react-router-dom';
import DeckService from '../service/DeckService';
import StatusDisplay from './StatusDisplay';
import moment from 'moment';

const DecksList = (props) => {
    const [currentDecks, setCurrentDecks] = useState('')

    useEffect(() => {
        const service = new DeckService()
        service.fetchDecks()
            .then(res => {
                // console.log("response from api", res.decks)
                let response = res.decks

                if (props.active) {
                    console.log('Show active')
                    let reversedResponse = response.map(item => item).reverse().filter(function (deck, index) {
                        return moment(deck.expiryDate).isAfter(moment())
                    });
                    setCurrentDecks(reversedResponse)
                } else {
                    console.log("Show inactive")
                    let reversedResponse = response.map(item => item).reverse().filter(function (deck, index) {
                        return moment(deck.expiryDate).isBefore(moment())
                    });
                    setCurrentDecks(reversedResponse)
                }
            })
    }, [props.active])



    return (
        <>
            {currentDecks.length < 1
                ?
                <div className="empty-decks-wrapper">
                    <div className="empty-decks">
                        {props.active
                            ?
                            <>
                                <img src="/images/decks-empty.png" alt="Nothing to show" />
                                <h4>Upload a slide deck</h4>
                                <b className="grey">You have no active slide decks. Use the button in the upper right corner.</b>
                            </>
                            :
                            <>
                                <img src="/images/archive-empty.png" alt="Nothing to show" />
                                <h4>This is your archive</h4>
                                <b className="grey">Here you will find expired presentations that are no longer accessible to your audience.</b>
                            </>
                        }
                    </div>
                </div>

                :
                <>
                    {currentDecks.map((deck, key) => (
                        props.active
                            ? <Link className="deck-row-wrapper" key={deck._id}
                                to={{ pathname: `/decks/${deck._id}` }}>
                                <div className="deck-row-thumbnail-spacer">
                                    <div className="deck-row-thumbnail-wrapper">
                                        <img src={`${deck.thumbnail}.jpg`} alt={deck.title} />
                                    </div>
                                </div>
                                <div className="deck-row-infos">
                                    <h4>{deck.title}</h4>
                                    <StatusDisplay expiryDate={deck.expiryDate} showExplanation={false} />
                                    <div className="deck-row-contacts"><img src="/images/contacts-1.svg" alt="Contacts" />

                                        {deck.contacts.length > 0
                                            ? <p className="no-margins">{deck.contacts.length} contacts</p>
                                            : <p className="no-margins">No contacts yet</p>}

                                    </div>
                                </div>

                                {deck.type === "Pro"
                                    ? <div className="deck-label-pro">PRO</div>
                                    : <div className="deck-label-free">Free</div>}

                            </Link>

                            : <Link className="deck-row-wrapper" key={deck._id}
                                to={{ pathname: `/archive/${deck._id}` }}>
                                <div className="deck-row-thumbnail-spacer">
                                    <div className="deck-row-thumbnail-wrapper">
                                        <img src={`${deck.thumbnail}.jpg`} alt={deck.title} />
                                    </div>
                                </div>
                                <div className="deck-row-infos">
                                    <h4>{deck.title}</h4>
                                    <StatusDisplay expiryDate={deck.expiryDate} showExplanation={false} />
                                    <div className="deck-row-contacts"><img src="/images/contacts-1.svg" alt="Contacts" />

                                        {deck.contacts.length > 0
                                            ? <p className="no-margins">{deck.contacts.length} contacts</p>
                                            : <p className="no-margins">No contacts yet</p>}

                                    </div>
                                </div>

                                {deck.type === "Pro"
                                    ? <div className="deck-label-pro">PRO</div>
                                    : <div className="deck-label-free">Free</div>}

                            </Link>

                    ))}
                </>
            }
        </>
    )
};

export default DecksList;