import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import './signup.css';
import AuthService from '../service/AuthService';
import { toast, ToastContainer } from 'react-toastify';
import './toast-styling.css';

export default class SignupEmail extends Component {

    state = {
        name: '',
        email: '',
        password: '',
        confirmpassword: '',
        submitButtonClass: false,
        tac: false,
        isSubmitted: false,
        service: new AuthService()
    }
    
    handleFormSubmit = (e) => {
        e.preventDefault();
        const emailRegex = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        if (!emailRegex.test(this.state.email)) {
            toast.error(<><img src="/images/toastify-cross.svg" alt="Something went wrong" />Invalid e-mail</>);
        } else {
            const name = this.state.name;
            const email = this.state.email;
            const password = this.state.password;
            let matchPassword = false;

            if (this.state.confirmpassword === this.state.password) {
                console.log("password is matching")
                matchPassword = true;
            } else {
                matchPassword = false;
                toast.error(<><img src="/images/toastify-cross.svg" alt="Something went wrong" />Password not matching</>);
            }

            (this.state.tac && matchPassword &&
                this.state.service.signup(name, email, password)
                    .then(res => {
                        localStorage.setItem('@pocpassport', JSON.stringify(res.tokenData));
                        this.setState({
                            name: "",
                            email: "",
                            password: "",
                            confirmpassword: "",
                            tac: false,
                            isSubmitted: true
                        });
                    })
                    .catch(e => console.log(e))
            )
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target
        if (e.target.type === "checkbox") {
            if (this.state.tac) {
                this.setState({
                    tac: !this.state.tac,
                    submitButtonClass: false
                })
            } else {
                this.setState({
                    tac: !this.state.tac,
                    submitButtonClass: true
                })
            }
        } else {
            this.setState({
                [name]: value
            })
        }
    }

    render() {
        return (
            <>

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="window-wrapper">
                    {this.state.isSubmitted && <Redirect to='/login' />}
                    <Link to="/"><img src="/images/logo-dark-v1.svg" alt="slides fox" className="logo-s1" /></Link>
                    <div className="window">
                        <h3>Sign up with email</h3>
                        <p className="grey">Already have an account? <Link to="/login" className="orange text-link">Log in</Link></p>
                        <form className="signup-form" onSubmit={this.handleFormSubmit}>
                            <input type="text" name="name" placeholder="Your name" value={this.state.name} onChange={e => this.handleChange(e)} required />
                            <input type="text" name="email" placeholder="Your e-mail" value={this.state.email} onChange={e => this.handleChange(e)} required />
                            <input type="password" name="password" placeholder="Choose password" value={this.state.password} onChange={e => this.handleChange(e)} required />
                            <input type="password" name="confirmpassword" placeholder="Confirm password" value={this.state.confirmpassword} onChange={e => this.handleChange(e)} required />
                            <div className="checkbox-wrapper">
                                <input type="checkbox" id="tac" name="tac" checked={this.state.tac} onChange={e => this.handleChange(e)} />
                                <label htmlFor="tac">I understand and agree to the <Link to="#" className="text-link s">terms & conditions</Link></label>
                            </div>
                            <input type="submit" value="Sign up" className="bt-standard" disabled={!this.state.submitButtonClass} />
                        </form>
                    </div>
                </div>
            </>
        )
    }
}
