import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './signup.css';
import AuthService from '../service/AuthService';
import { ToastContainer, toast } from 'react-toastify';
import './toast-styling.css';
import GoogleLogin from 'react-google-login';
// import { FcGoogle } from 'react-icons/fc';

export default class Login extends Component {

    state = {
        email: '',
        password: '',
        isSubmitted: false,
        service: new AuthService()
    }

    handleGoogleLogin = (response) => {
        console.log("google response1 :", response.profileObj)
        const newUser = {
            email: response.profileObj.email,
            name: response.profileObj.name
          }
        this.state.service.googleLogin(response)
        .then(result => {
          console.log("google response2 :", result) 
          localStorage.setItem('@pocpassport', JSON.stringify(result));
          this.props.setUser(newUser)
          this.props.history.push('/decks')
        })
      };

    handleFormSubmit = (e) => {
        e.preventDefault();
        const emailRegex = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        // console.log("email check", emailRegex.test(this.state.email))
        if (!emailRegex.test(this.state.email)) {
            toast.error(<><img src="/images/toastify-cross.svg" alt="Something went wrong" />Invalid e-mail</>);
        } else {
            const email = this.state.email;
            const password = this.state.password;
            this.state.service.login(email, password)
                .then(res => {
                    localStorage.setItem('@pocpassport', JSON.stringify(res.tokenData));
                    this.setState({ email: "", password: "", isSubmitted: true });
                    this.props.setUser(res.user)
                    console.log(this.props.history)
                    this.props.history.push('/decks')
                })
                .catch(e => console.log(e))
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    render() {
        return (
            <>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="window-wrapper">
                    <Link to="/"><img src="/images/logo-dark-v1.svg" alt="slides fox" className="logo-s1" /></Link>
                    <div className="window">
                        <h3>Log in</h3>
                        <p className="grey">Don't have an account yet? <Link to="/signup" className="orange text-link">Sign up for free</Link></p>
                        <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                            render={(renderProps) => (
                            <button
                                type='button'
                                // className='bt-standard flex items-center justify-around w-3/5 hover:underline focus:outline-none'
                                className='bt-standard'
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                            >
                                {/* <FcGoogle /> */}
                                <img src="./images/google-icon.svg" alt="Google"/>
                                Continue with Google
                            </button>
                            )}
                            buttonText='Login'
                            onSuccess={this.handleGoogleLogin}
                            onFailure={this.handleGoogleLogin}
                            cookiePolicy={'single_host_origin'}
                        />
                        <hr />
                        <p className="grey text-caps-muted">or</p>

                        <form onSubmit={this.handleFormSubmit} className="signup-form">
                            <input type="text" name="email" placeholder="Your e-mail" value={this.state.email} onChange={e => this.handleChange(e)} />
                            <input type="password" name="password" placeholder="Enter password" value={this.state.password} onChange={e => this.handleChange(e)} />
                            <input type="submit" value="Log in" className="bt-standard" />
                        </form>
                    </div>
                </div>
            </>
        )
    }
}
