import axios from 'axios';

class UserService {
    constructor() {
      this.service = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        withCredentials: true
      })
    }
  
    user = JSON.parse(localStorage.getItem('@pocpassport'))

    editPersonal = (name, email) => {
      return this.service({
        method: 'PUT',
        url:'/users/editpersonal',
        headers: { 'Authorization': this.user.token},
        data: {name: name, email: email}     
      })      
      .then(response => response.data)
    }

    editPassword = (oldPassword, newPassword) => {
      return this.service({
        method: 'PUT',
        url:'/users/editpassword',
        headers: { 'Authorization': this.user.token},
        data: {oldPassword: oldPassword, newPassword: newPassword}    
      })       
      .then(response => response.data)     
    }

    editProfilePicture = (image) => {
      return this.service({
        method: 'PUT',
        url:'/users/profilepicture',
        headers: { 'Authorization': this.user.token},
        data: {profilePicture: image}   
      }) 
      .then(response => response.data)
    }

    editLogo = (image) => {
      return this.service({
        method: 'PUT',
        url:'/users/logo',
        headers: { 'Authorization': this.user.token},
        data: {logo: image}  
      })
      .then(response => response.data)    
    }

    show = () => {
      return this.service.get('/profile/show', {
        headers: { Authorization: this.user.token },
      })
      .then(response => response.data)
    }
  
  }
  
  export default UserService