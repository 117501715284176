import React, { Component } from 'react'
import './App.css';
import Home from './components/Home';
import Spinner from './components/Spinner';
import Signup from './components/Signup';
import SignupEmail from './components/SignupEmail';
import Login from './components/Login';
import Decks from './components/Decks';
import DeckDetails from './components/DeckDetails';
import ArchiveDetails from './components/ArchiveDetails';
import Download from './components/Download';
import Archive from './components/Archive';
import Account from './components/Account';
import Credits from './components/Credits';
import FAQ from './components/FAQ';
import Contact from './components/Contact';
import Styleguide from './components/Styleguide';
// import Error from './components/Error';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ResetPassword from './components/Resetpassword';
import AuthService from './service/AuthService';

export default class App extends Component {
  state = {
    user: null,
    loading: true
  }
  setUser = (user) => {
    this.setState({
      user: user,
      loading: false
    })
  }

  service = new AuthService()

  checkAuthenticated = () => {
    console.log("In checkAuthenticated")
    if(this.state.user === null) {
      this.service.isAuthenticated()
      .then(response => {
          console.log("response from isAuthenticated",response)
          this.setState({
            user: response,
            loading: false
          })
        })
        .catch( err => {
          console.log("err in checkAuthenticated:", err)
          this.setState({
            user: false,
            loading: false
          })
      })
    }

    return this.state.user ?  true : false
  }

 userLogout= () => {
    localStorage.removeItem('@pocpassport') 
    this.service.logout()
     .then(res => {
      this.setState({
        user: false,
        loading: false
      })
     })
     .catch(e => {
        this.setState({
          user: false,
          loading: false
        })
     })
 }

  render() {
    let isUserLoggedIn = this.checkAuthenticated()
    const appContent = 
  <Switch>    
    <Route path="/decks/:deckId/download" component={Download} />
    <Route path="/login" component={(props) =>  <Login {...props} setUser={this.setUser}/>} />
    {/* <Route component={Error} /> */}
    <Route path="/" component={Home} exact={true} />
    <Route path="/signup" component={Signup} />
    <Route path="/signup-email" component={SignupEmail} />          
    <Route path="/reset-password" component={(props) => <ResetPassword {...props} isUserLoggedIn={isUserLoggedIn}/>} />    
    <Route path="/decks" component={(props) => <Decks {...props} isUserLoggedIn={isUserLoggedIn}/>} exact={true} />
    <Route path="/archive" component={(props) => <Archive {...props} isUserLoggedIn={isUserLoggedIn}/>} exact={true} />
    <Route path="/archive/:deckId" exact={true} component={(props) => <ArchiveDetails {...props} isUserLoggedIn={isUserLoggedIn}/>} />
    <Route path="/decks/:deckId" exact={true} component={(props) => <DeckDetails {...props} isUserLoggedIn={isUserLoggedIn} />} />
    <Route path="/account" component={(props) =>  <Account {...props} user={this.state.user} isUserLoggedIn={isUserLoggedIn} setUser={this.setUser} userLogout={this.userLogout}/>} />
    <Route path="/credits" component={(props) => <Credits {...props} isUserLoggedIn={isUserLoggedIn} />} />
    <Route path="/faq" component={(props) => <FAQ {...props} isUserLoggedIn={isUserLoggedIn} />} />
    <Route path="/contact" component={(props) => <Contact {...props} isUserLoggedIn={isUserLoggedIn}/>} />
    <Route path="/style" component={(props) => <Styleguide {...props} isUserLoggedIn={isUserLoggedIn} />} />
</Switch> 

    return (
      <div className="App">
        <BrowserRouter>
        { this.state.loading ? <Spinner /> : <>{appContent} </>}
        </BrowserRouter> 
      </div>
    )
  }
}