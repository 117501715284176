import React, { Component } from 'react'
import Navigation from './Navigation'
import './faq.css'
import { Redirect } from 'react-router-dom'

export default class Help extends Component {
    render() {
        return (
            this.props.isUserLoggedIn
            ?   <div className="main-wrapper">
                    <Navigation />
                    <div className="workspace-spacer">
                        <div className="workspace-wrapper">
                            <div className="workspace-title-wrapper"><h4>FAQ</h4><button className="bt-standard">Contact us</button></div>
                            <div className="workspace-main-content">

                                <div className="faq-container">
                                    <div className="faq-questions-container">

                                        <div className="faq-question-wrapper">
                                            <h5>How do I upload my presentation?</h5> 
                                            <p className="text-s grey">We believe all kinds of presentations can be improved with our tool, but we have developed this tool for speakers who are trying to imprint some form of change on the side of their audience – for example in thought or behaviour.</p>
                                        </div>

                                        <div className="faq-question-wrapper">
                                            <h5>What happens when my audience follows the QR code?</h5> 
                                            <p className="text-s grey">We believe all kinds of presentations can be improved with our tool, but we have developed this tool for speakers who are trying to imprint some form of change on the side of their audience – for example in thought or behaviour.</p>
                                        </div>

                                        <div className="faq-question-wrapper">
                                            <h5>Does my audience receive the slides right away?</h5> 
                                            <p className="text-s grey">No, since you probably only want them to receive your slides after your presentation is finished. That's why there is a delay of one hour after your audience registers before they receive the download link.</p>
                                        </div>

                                        <div className="faq-question-wrapper">
                                            <h5>How do I upload my presentation?</h5> 
                                            <p className="text-s grey">We believe all kinds of presentations can be improved with our tool, but we have developed this tool for speakers who are trying to imprint some form of change on the side of their audience – for example in thought or behaviour.</p>
                                        </div>

                                        <div className="faq-question-wrapper">
                                            <h5>How do I share a presentation with my audience?</h5> 
                                            <p className="text-s grey">We believe all kinds of presentations can be improved with our tool, but we have developed this tool for speakers who are trying to imprint some form of change on the side of their audience – for example in thought or behaviour.</p>
                                        </div>

                                        <div className="faq-question-wrapper">
                                            <h5>Do you have tips for working with the QR code?</h5> 
                                            <p className="text-s grey">We believe all kinds of presentations can be improved with our tool, but we have developed this tool for speakers who are trying to imprint some form of change on the side of their audience – for example in thought or behaviour.</p>
                                        </div>

                                        <div className="faq-question-wrapper">
                                            <h5>How do I upload my presentation?</h5> 
                                            <p className="text-s grey">We believe all kinds of presentations can be improved with our tool, but we have developed this tool for speakers who are trying to imprint some form of change on the side of their audience – for example in thought or behaviour.</p>
                                        </div>

                                        <div className="faq-question-wrapper">
                                            <h5>How do I share a presentation with my audience?</h5> 
                                            <p className="text-s grey">We believe all kinds of presentations can be improved with our tool, but we have developed this tool for speakers who are trying to imprint some form of change on the side of their audience – for example in thought or behaviour.</p>
                                        </div>

                                        <div className="faq-question-wrapper">
                                            <h5>Do you have tips for working with the QR code?</h5> 
                                            <p className="text-s grey">We believe all kinds of presentations can be improved with our tool, but we have developed this tool for speakers who are trying to imprint some form of change on the side of their audience – for example in thought or behaviour.</p>
                                        </div>

                                        <div className="faq-question-wrapper">
                                            <h5>How do I upload my presentation?</h5> 
                                            <p className="text-s grey">We believe all kinds of presentations can be improved with our tool, but we have developed this tool for speakers who are trying to imprint some form of change on the side of their audience – for example in thought or behaviour.</p>
                                        </div>

                                        <div className="faq-question-wrapper">
                                            <h5>How do I share a presentation with my audience?</h5> 
                                            <p className="text-s grey">We believe all kinds of presentations can be improved with our tool, but we have developed this tool for speakers who are trying to imprint some form of change on the side of their audience – for example in thought or behaviour.</p>
                                        </div>

                                        <div className="faq-question-wrapper">
                                            <h5>Do you have tips for working with the QR code?</h5> 
                                            <p className="text-s grey">We believe all kinds of presentations can be improved with our tool, but we have developed this tool for speakers who are trying to imprint some form of change on the side of their audience – for example in thought or behaviour.</p>
                                        </div>

                                    </div>

                                    <div className="faq-contact-us-container">

                                        <div className="faq-contact-us-wrapper">
                                            <img src="/images/contact-us-character.png" alt="Contact us"/>
                                        <h4>Question not answered?</h4>
                                        <p className="text-s">You can always <span className="orange">contact us</span></p>

                                        </div>


                                    </div>




                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            : <Redirect to="/" /> 
        )
    }
}
