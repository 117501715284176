import React, { Component } from 'react'
import Navigation from './Navigation'
import './faq.css'
import './credits.css'
import { Redirect } from 'react-router-dom'

export default class Credits extends Component {
    render() {
        return (
            this.props.isUserLoggedIn
            ?   <div className="main-wrapper">
                    <Navigation />
                    <div className="workspace-spacer">
                        <div className="workspace-wrapper">
                            <div className="workspace-title-wrapper"><h4>Credits</h4>

                                <div className="credits-widget-container">
                                    <img src="/images/coin-stack.svg" alt="credits" />
                                    <div className="credits-widget-text-wrapper">
                                        <p className="text-s-caps grey">My credits</p>
                                        <p className="text-s">X credits left</p>
                                    </div>
                                </div>

                            </div>
                            <div className="workspace-main-content">


                                <div className="credits-pricing-container">

                                    <div className="credits-pricing-tier-wrapper">
                                        <div className="tier-highlight-bar">

                                        </div>

                                        <div className="tier-content">
                                            <div className="tier-top-section">
                                                <div className="deck-label-free">Free</div>
                                                <h4>Starter</h4>
                                                <p className="text-s grey">For small groups and simple presentations</p>
                                                <p className="price">0 €</p>
                                            </div>

                                            <div className="tier-features">
                                                <div className="no-of-decks"></div>
                                                <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">5 MB file size limit <span className="feature-tooltiptext">How large your uploaded file may be</span></p></span>
                                                <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">3 days online availability <span className="feature-tooltiptext">How long your file will be downloadable</span></p></span>
                                                <span className="feature-row"><img src="/images/feature-cross.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">No custom branding <span className="feature-tooltiptext">Show your logo on the download page</span></p></span>
                                                <span className="feature-row"><img src="/images/feature-cross.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">No CSV download <span className="feature-tooltiptext">Ability to export contacts as CSV</span></p></span>
                                            </div>
                                            <div className="tier-cta">
                                            </div>
                                        </div>


                                    </div>

                                    <div className="credits-pricing-tier-wrapper">
                                        <div className="tier-highlight-bar popular">
                                            popular
                                        </div>

                                        <div className="tier-content popular-outline">
                                            <div className="tier-top-section">
                                                <div className="deck-label-pro">Pro</div>
                                                <h4>Standard pack</h4>
                                                <p className="text-s grey fixwidth">For most professional presenters</p>
                                                <p className="price">6,99 €</p>
                                                <p className="price-per-deck">1,39 € / deck</p>
                                                <p className="grey text-tiny">one-time payment, billed once</p>
                                            </div>

                                            <div className="tier-features">
                                                <div className="no-of-decks"><img src="/images/pricing-decks.svg" alt="" />5 slide decks</div>
                                                <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">50 MB file size limit <span className="feature-tooltiptext">How large your uploaded file may be</span></p></span>
                                                <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">30 days online availability <span className="feature-tooltiptext">How long your file will be downloadable</span></p></span>
                                                <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">Custom branding <span className="feature-tooltiptext">Show your logo on the download page</span></p></span>
                                                <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">CSV download <span className="feature-tooltiptext">Ability to export contacts as CSV</span></p></span>

                                            </div>
                                            <div className="tier-cta">
                                                <div className="bt-s">Buy standard pack</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="credits-pricing-tier-wrapper">
                                        <div className="tier-highlight-bar">

                                        </div>

                                        <div className="tier-content">
                                            <div className="tier-top-section">
                                                <div className="deck-label-pro">Pro</div>
                                                <h4>Value pack</h4>
                                                <p className="text-s grey">For professionals with many presentations</p>
                                                <p className="price">9,99 €</p>
                                                <p className="price-per-deck">0,99 € / deck</p>
                                                <p className="grey text-tiny">one-time payment, billed once</p>
                                            </div>

                                            <div className="tier-features">
                                                <div className="no-of-decks"><img src="/images/pricing-decks.svg" alt="" />10 slide decks</div>
                                                <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">50 MB file size limit <span className="feature-tooltiptext">How large your uploaded file may be</span></p></span>
                                                <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">30 days online availability <span className="feature-tooltiptext">How long your file will be downloadable</span></p></span>
                                                <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">Custom branding <span className="feature-tooltiptext">Show your logo on the download page</span></p></span>
                                                <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">CSV download <span className="feature-tooltiptext">Ability to export contacts as CSV</span></p></span>

                                            </div>
                                            <div className="tier-cta">
                                                <div className="bt-s">Buy value pack</div>
                                            </div>
                                        </div>

                                    </div>


                                </div>




                                <div className="faq-container">
                                    <div className="faq-questions-container">

                                        <div className="faq-question-wrapper">
                                            <h5>How do I upload my presentation?</h5>
                                            <p className="text-s grey">We believe all kinds of presentations can be improved with our tool, but we have developed this tool for speakers who are trying to imprint some form of change on the side of their audience – for example in thought or behaviour.</p>
                                        </div>

                                        <div className="faq-question-wrapper">
                                            <h5>How do I share a presentation with my audience?</h5>
                                            <p className="text-s grey">We believe all kinds of presentations can be improved with our tool, but we have developed this tool for speakers who are trying to imprint some form of change on the side of their audience – for example in thought or behaviour.</p>
                                        </div>

                                        <div className="faq-question-wrapper">
                                            <h5>Do you have tips for working with the QR code?</h5>
                                            <p className="text-s grey">We believe all kinds of presentations can be improved with our tool, but we have developed this tool for speakers who are trying to imprint some form of change on the side of their audience – for example in thought or behaviour.</p>
                                        </div>

                                        <div className="faq-question-wrapper">
                                            <h5>How do I upload my presentation?</h5>
                                            <p className="text-s grey">We believe all kinds of presentations can be improved with our tool, but we have developed this tool for speakers who are trying to imprint some form of change on the side of their audience – for example in thought or behaviour.</p>
                                        </div>

                                        <div className="faq-question-wrapper">
                                            <h5>How do I share a presentation with my audience?</h5>
                                            <p className="text-s grey">We believe all kinds of presentations can be improved with our tool, but we have developed this tool for speakers who are trying to imprint some form of change on the side of their audience – for example in thought or behaviour.</p>
                                        </div>

                                    </div>

                                    <div className="faq-contact-us-container">

                                        <div className="faq-contact-us-wrapper">
                                            <img src="/images/contact-us-character.png" alt="Contact us" />
                                            <h4>Question not answered?</h4>
                                            <p className="text-s">You can always <span className="orange">contact us</span></p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            : <Redirect to="/" /> 
        )
    }
}
