import axios from 'axios'

class AuthService {
  constructor() {
    this.service = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      withCredentials: true
    })
  }

  signup = (name, email, password) => {
    return this.service.post('/auth/signup', {name, email, password})
    .then(response => response.data)
  }

  login = (email, password) => {
    return this.service.post('/auth/login', {email, password})
    .then(response => response.data)
  }

  googleLogin = (response) => {
    console.log("google response :", response)
    const { accessToken } = response;
    if (!accessToken) return null;
    const googleresponse = {
      access_token: accessToken,
    };
    return this.service.post('/auth/googleLogin', googleresponse)
    .then(response => response.data)
  }

  isAuthenticated = () => {
    const user = JSON.parse(localStorage.getItem('@pocpassport'));
    if(user){
      return this.service.get('/auth/isLoggedIn' , {
        headers: { Authorization: user.token },
      })
      .then(response => response.data)
    } else {
      return this.service.get('/auth/isLoggedIn')
      .then(response => response.data)
    }
  }

  logout = () => {
      return this.service.get('/auth/logout')
      .then(response => response.data)
  }
}

export default AuthService