import React from 'react'
import './contactlist.css'
import moment from 'moment'

export default function ContactList(contacts) {
    return (
        <table cellSpacing="0" cellPadding="0">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>E-Mail</th>
                    <th>Date added</th>
                </tr>
            </thead>
            <tbody>
                {
                    contacts.props.map((contact, key) => (
                        <tr key={contact.name}>
                            <td>{contact.name}</td>
                            <td>{contact.email}</td>
                            <td>{moment(contact.createdAt).fromNow()}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}
