import axios from 'axios'

class ContactService {
  constructor() {
    this.service = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      withCredentials: true
    })
  }
  createContact = (deckId, contactName, contactEmail) => {
    return this.service.post(`/decks/${deckId}/contacts`, { name: contactName, email: contactEmail})
    .then(response => response.data)
  }

}

export default ContactService