import React, { Component } from 'react';
import StatusDisplay from './StatusDisplay';
import moment from 'moment';
import Service from '../service/DeckService';
import AuthService from '../service/AuthService';
import { toast, ToastContainer } from 'react-toastify';
import './toast-styling.css';
export default class ChangeStatus extends Component {

    state = {
        credits: 0
    }

    componentDidMount = () => {
        const authService = new AuthService()
        authService.isAuthenticated()
            .then(res => {
                this.setState({ credits: res.credits })
            })
            .catch(e => console.log("Error :", e))
    }


    onClickHandler = (e) => {
        e.preventDefault();
        const service = new Service();
        let deckAction, deckType;
        if (moment(this.props.deck.expiryDate).isBefore(moment())) {
            deckAction = "reactivate";
            deckType = "Pro"
            if (this.state.credits === 0) {
                toast.error(<><img src="/images/toastify-cross.svg" alt="Something went wrong" />Insufficient credits</>);
                return
            }
        } else {
            deckAction = "archive";
            deckType = this.props.deck.type
        }

        service.changeStatusDeck(this.props.deck._id, deckAction, deckType)
            .then((deck) => {
                this.props.hide()
                // this.props.updateDeck(deck)
                if (deckAction === "reactivate") {
                    this.props.history.push(`/decks/${this.props.deck._id}`)
                } else {
                    this.props.history.push(`/archive/${this.props.deck._id}`)
                }
                return
            })
            .catch(e => console.log("Error in changeStatusDeck :", e))
    }

    render() {
        return (
            <>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="add-deck-popup-wrapper">
                    <div className="archive-popup-container">
                        <div className="title-row">

                            {
                                moment(this.props.deck.expiryDate).isBefore(moment())
                                    ? <h4>Re-activate deck</h4>
                                    : <h4>Archive deck</h4>
                            }

                            <img src="/images/close-x.svg" alt="close popup" onClick={this.props.hide} />
                        </div>
                        <form onSubmit={(e) => this.onClickHandler(e)}>
                            <div className="form-row">
                                {
                                    moment(this.props.deck.expiryDate).isBefore(moment())
                                        ? <p className="text-s grey">You are about to re-activate the following presentation. Your slide deck's download page currently no longer accepts registrations.</p>
                                        : <p className="text-s grey">You are about to archive the following presentation.</p>

                                }

                            </div>

                            <div className="archive-deck-row-wrapper">
                                <div className="deck-row-thumbnail-spacer">
                                    <div className="deck-row-thumbnail-wrapper">
                                        <img src={`${this.props.deck.thumbnail}.jpg`} alt={this.props.deck.title} />
                                    </div>
                                </div>
                                <div className="deck-row-infos">
                                    <h4>{this.props.deck.title}</h4>
                                    <StatusDisplay expiryDate={this.props.deck.expiryDate} showExplanation={false} />
                                    {/* <div className="deck-row-contacts"><img src="/images/contacts-1.svg" alt="Contacts" />
                                    {this.props.contacts.length > 0
                                        ? <p className="no-margins">{this.props.contacts.length} contacts</p>
                                        : <p className="no-margins">No contacts yet</p>}
                                </div> */}
                                </div>
                                {/* {this.props.type === "Pro"
                                    ? <div className="deck-label-pro">PRO</div>
                                    : <div className="deck-label-free">Free</div>} */}
                            </div>


                            {
                                moment(this.props.deck.expiryDate).isBefore(moment())
                                    ? <div className="popup-notification-wrapper"><img src="/images/icon-attention.svg" alt="Explanation" />
                                        <p className="popup-notification-text">
                                            You can only re-activate a slide deck as a PRO deck. <br /><br />
                                    To re-activate this slide deck, a credit will be deducted from your account.</p>
                                        <div className="popup-reactivate-col">
                                            <div className="popup-reactivate-credits-display">
                                                <div className="credits-display-unit"><img src="/images/credits.svg" alt="" /> <div className="text-s-caps">
                                                    My credits</div> <div className="text-s grey">{this.state.credits} Credits left</div></div>

                                                <div className="text-s orange">Buy more</div>
                                            </div>
                                            <div className="popup-reactivate-credits-pro-features">

                                                <div className="no-of-decks"><div className="deck-label-pro">PRO</div> <div className="reactivate-headline"></div>Re-activate this slide deck</div>
                                                <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">30 days online availability <span className="feature-tooltiptext">How long your file will be downloadable</span></p></span>
                                                <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">Custom branding <span className="feature-tooltiptext">Show your logo on the download page</span></p></span>
                                                <span className="feature-row"><img src="/images/feature-check.svg" alt="feature" className="feature-icon" /><p className="feature-tooltip">CSV download <span className="feature-tooltiptext">Ability to export contacts as CSV</span></p></span>

                                            </div>
                                        </div>
                                    </div>
                                    : <div className="popup-notification-wrapper"><img src="/images/icon-attention.svg" alt="Explanation" /><p className="popup-notification-text">
                                        Once you archive this slide deck, your download page will no longer accept registrations and contacts can no longer download your slide deck file from us.</p></div>

                            }

                            <div className="final-row">
                                <div className="bt-standard-ghost" onClick={this.props.hide} >Cancel</div>

                                {
                                    moment(this.props.deck.expiryDate).isBefore(moment())
                                        ? <input type="submit" value="Re-activate deck" className="bt-standard" />
                                        : <input type="submit" value="Archive deck" className="bt-standard" />

                                }


                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}
