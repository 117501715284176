import axios from 'axios'

class DeckService {
  constructor() {
    this.service = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      withCredentials: true
    })
  }

  user = JSON.parse(localStorage.getItem('@pocpassport'));

  fetchDecks = () => {
    return this.service.get(`/decks/`, {
      headers: { Authorization: this.user.token },
    })
    .then(response => response.data)
  }

  fetchDeck = (deckId) => {
    return this.service.get(`/decks/${deckId}`, {
      headers: { Authorization: this.user.token },
    })
    .then(response => response.data)
  }
  
  fetchDeckPublic = (deckId) => {
    return this.service.get(`/decks/${deckId}/public`)
    .then(response => response.data)  
  }

  changeStatusDeck = (deckId, deckAction, deckType) => {
    return this.service({
      method: 'PUT',
      url:`/decks/${deckId}`,
      headers: { 'Authorization': this.user.token },
      data: {deckAction, deckType}     
    })
    .then(response => response.data) 
  } 
}

export default DeckService