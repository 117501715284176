import React, { useState } from "react";
import Navigation from "./Navigation";
import UploadService from "../service/UploadService";
import UserService from "../service/UserService";
import "./Account.css";
import { Redirect } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import './toast-styling.css';

const Account = (props) => {
  const uploadservice = new UploadService();
  const userservice = new UserService();

  let [name, setName] = useState(props.user.name);
  let [email, setEmail] = useState(props.user.email);
  let [currentPassword, setCurrentPassword] = useState("");
  let [newPassword, setNewPassword] = useState("");
  let [repeatPassword, setRepeatPassword] = useState("");
  let [matchPassword, setMatchPassword] = useState(false);
  let [logo, setLogo] = useState(props.user.logo);
  let [profilePicture, setProfilePicture] = useState(props.user.profilePicture);
  let [profUpdBtn, setProfUpdBtn] = useState(true);
  let [logoUpdBtn, setLogoUpdBtn] = useState(true);

  const handleChange = (e) => {
    e.target.name === "name" && setName(e.target.value);
    e.target.name === "email" && setEmail(e.target.value);
    e.target.name === "currentPassword" && setCurrentPassword(e.target.value);
    e.target.name === "newPassword" && setNewPassword(e.target.value);
    e.target.name === "repeatPassword" && setRepeatPassword(e.target.value);
  };

  const handlePersonalSubmit = (e) => {
    e.preventDefault()
      userservice.editPersonal(name, email)
       .then( resp => props.setUser(resp.user))
       .catch(e => console.log("Error :", e))
  }

  const handlePassword = (e) => {
    setRepeatPassword(e.target.value);
    (newPassword === e.target.value ? setMatchPassword(true) : setMatchPassword(false))
  };
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    (matchPassword
     ? userservice.editPassword(currentPassword, newPassword)
        .then( resp => { 
          console.log("Password updated")
          // toast.error("Password updated")
          toast.success(<><img src="/images/toastify-check.svg" alt="All good" />Password updated</>)
          // props.setUser(resp.user)
          return true
        }
        )
        .catch(e => console.log("Error :", e))
     : toast.error(<><img src="/images/toastify-cross.svg" alt="Something went wrong" />Password not matching</>)
    )
  }

  const enableProfUpdBtn = (e) => {
    logoUpdBtn && (e.target.files[0] ? setProfUpdBtn(false) : setProfUpdBtn(true)) 
  }

  const enableLogoUpdBtn = (e) => {
    profUpdBtn && (e.target.files[0] ? setLogoUpdBtn(false) : setLogoUpdBtn(true))
  }

  const handleProfilePicSubmit = (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append('profilePicture', e.target[0].files[0])
    uploadservice.uploadProfilePicture(data)
      .then(resp => userservice.editProfilePicture(resp.profilePicture))
      .then(resp => {
        setProfilePicture(resp.user.profilePicture)
        props.setUser(resp.user)
      })
      .catch(err => console.log("Error",err)) 
  }
  
  const deleteProfileImage = () => {
    userservice.editProfilePicture("")
      .then(resp => {
        setProfilePicture(resp.user.profilePicture)
        props.setUser(resp.user)
      })
      .catch(err => console.log("Error",err)) 
  }

  const handleLogoSubmit = (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append('logo', e.target[0].files[0])
    uploadservice.uploadLogo(data)
      .then(resp => userservice.editLogo(resp.logo))
      .then(resp => {
        setLogo(resp.user.logo)
        props.setUser(resp.user)
      })
      .catch(e => console.log("Error",e))   
  }

  const deleteLogo = () => {
    userservice.editLogo("")
    .then(resp => {
      setLogo(resp.user.logo)
      props.setUser(resp.user)
    })
    .catch(e => console.log("Error",e))  
  }
  
  return (
    props.isUserLoggedIn
    ? 
    <>
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    <div>
        <div className="main-wrapper">
          <Navigation />
          <div className="workspace-spacer">
            <div className="workspace-wrapper">
              <div className="workspace-title-wrapper">
                <h4>Account</h4>
                <button className="bt-outline bt-icon" onClick={props.userLogout}>
                  <img src="/images/logout.svg" alt="Log out" />
                  Log out
                </button>
              </div>
              <div className="workspace-main-content">
                <div className="form-row border-bottom">
                  <div className="form-info">
                    <h5>Personal</h5>
                    <p className="text-s grey">
                      Your full name and email address.
                    </p>
                  </div>
                  <form
                    className="form-vertical-radios"
                    onSubmit={(e) => handlePersonalSubmit(e)}
                  >
                    <label name="name" className="add-deck-form-label">NAME</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={name}
                      onChange={(e) => handleChange(e)}
                      className="account-input"input
                    />
                    <label name="email" className="add-deck-form-label">EMAIL</label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      value={email}
                      onChange={(e) => handleChange(e)}
                      className="account-input"
                    />
                    <button className="bt-standard bt-small">UPDATE</button>
                  </form>
                </div>
                <div className="form-row border-bottom">
                  <div className="form-info">
                    <h5>Images</h5>
                    <p className="text-s grey">You can add a profile picture and a logo to your account. The logo will be displayed to your audience on your download pages (PRO only).</p>
                  </div>
                  <div>
                    <form
                      className="profile-image-update-form"
                      onSubmit={(e) => handleProfilePicSubmit(e)}
                    >
                      <p className="add-deck-form-label">
                        {/* <pre> */}
                          PROFILE IMAGE{" "}
                          <span className="text-s grey"> (Optional)</span>
                        {/* </pre> */}
                      </p>
                      <div className="flex-row">
                        <div className="profile-img-container">
                          <img
                            src="/images/base.svg"
                            className="profile-holder"
                            alt="profile avatar"
                          ></img>
                          <img
                            src={ profilePicture 
                                ? `${profilePicture}`
                                : "/images/Vector.svg"
                            }
                            className="profile-img"
                            alt="vector avatar"
                          ></img>
                          { profilePicture 
                          && <img
                                src="/images/delete-image.png" 
                                className="profile-delete-img"
                                alt="vector delete"
                                onClick={deleteProfileImage}
                            ></img>
                          }                        
                        </div>    
                        <div className="text-s orange">
                          <input type="file" name="profilePicture" onChange={enableProfUpdBtn}/>
                        </div>
                      </div>
                      <input type="submit" value="Update" className="bt-standard" disabled={profUpdBtn} />
                      {/* <button type="submit" className="bt-standard bt-small" disabled={profUpdBtn}>UPDATE</button> */}
                    </form>
                    <form
                      className="profile-image-update-form"
                      onSubmit={(e) => handleLogoSubmit(e)}
                    >
                      <p className="add-deck-form-label" id="logo-section">
                        COMPANY LOGO{" "}
                        <span className="text-s grey"> (Optional)</span>
                      </p>
                      <div className="flex-row">
                        <div className="logo-container">
                          {logo  
                          ? <img src={`${logo}`} alt='profile avatar'></img> 
                          : <p className="orange">Your logo</p>
                          }
                          { logo 
                          && <img
                                src="/images/delete-image.png" 
                                className="logo-delete-img"
                                alt="vector delete"
                                onClick={deleteLogo}
                            ></img>
                          }
                        </div>
                        <div className="text-s orange">
                            <input type="file" name="logo" onChange={enableLogoUpdBtn} />
                        </div>
                      </div>
                      <input type="submit" value="Update" className="bt-standard" disabled={logoUpdBtn} />
                    </form>
                  </div>
                </div>
                <div className="form-row border-bottom">
                  <div className="form-info">
                    <h5>Password</h5>
                    <p className="text-s grey">Your login password.</p>
                  </div>
                  <form
                    className="form-vertical-radios"
                    onSubmit={(e) => handlePasswordSubmit(e)}
                  >
                    <label name="name" className="add-deck-form-label">CURRENT PASSWORD</label>
                    <input
                      type="password"
                      name="currentPassword"
                      id="currentPassword"
                      className="account-input"
                      value={currentPassword}
                      onChange={(e) => handleChange(e)}
                    />
                    <label name="newPassword" className="add-deck-form-label">NEW PASSWORD</label>
                    <input
                      type="password"
                      name="newPassword"
                      id="newPassword"
                      className="account-input"
                      value={newPassword}
                      onChange={(e) => handleChange(e)}
                    />
                    <label name="repeatPassword" className="add-deck-form-label">CONFIRM NEW PASSWORD</label>
                    <input
                      type="password"
                      name="repeatPassword"
                      id="repeatPassword"
                      className="account-input"
                      value={repeatPassword}
                      onChange={(e) => handlePassword(e)}
                    />
                    <button className="bt-standard bt-small">UPDATE</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    : <Redirect to="/" />   
  );
};

export default Account;
